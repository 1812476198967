import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { isStorybook } from './utils/environment';

export const cardBrandNoBordersIconBaseUrl =
  'https://static.parastorage.com/services/payments-my-wallet/1.417.0/assets/icons';

export const SITE_CURRENCY_DEFAULT = 'USD';

export const supportedPaymentMethodType: string[] = [PaymentMethod.CreditCard];

if (isStorybook()) {
  supportedPaymentMethodType.push(PaymentMethod.PayPal);
}
