import { createAsyncThunk } from '@reduxjs/toolkit';
import type { SavedPaymentMethodWithMeta } from '@appTypes';
import { Interaction } from '@constants';
import { DemoView } from '@settings/constants';
import { WarmupDataKey } from '@services/WarmupData';
import { sortPaymentMethods } from '@utils/paymentMethod';
import { appActions } from '@infrastructure/redux/app';
import type { AppState } from '../../rootReducer';
import type { ThunkApiConfig } from '../../store.types';
import { getMethodById } from '../selectors';
import type { FetchThunk } from './types';

const hasPrimaryMethodChanged = (
  state: AppState,
  paymentMethods?: SavedPaymentMethodWithMeta[],
): boolean | undefined => {
  const serverPrimaryMethod = paymentMethods?.find((method) => method.primary);

  if (serverPrimaryMethod) {
    const clientMethod = getMethodById(serverPrimaryMethod.id)(state);

    return clientMethod && !clientMethod.primary;
  }

  return false;
};

export const fetchSavedPaymentMethods = createAsyncThunk<
  SavedPaymentMethodWithMeta[] | undefined,
  FetchThunk | undefined,
  ThunkApiConfig
>(
  'savedPaymentMethods/fetch',
  async ({ demoView } = {}, { extra, dispatch, getState, rejectWithValue }) => {
    const { flowAPI, warmupData, savedPaymentMethodsApi } = extra;
    const { environment, fedops, errorMonitor, errorHandler } = flowAPI;

    try {
      fedops.interactionStarted(Interaction.LoadPaymentMethods);

      if (environment.isViewer) {
        const savedPaymentMethods = await savedPaymentMethodsApi.getAll();

        fedops.interactionEnded(Interaction.LoadPaymentMethods);

        const methods = savedPaymentMethods
          ? sortPaymentMethods(savedPaymentMethods)
          : [];

        warmupData.set(WarmupDataKey.SavedPaymentMethods, methods);

        if (hasPrimaryMethodChanged(getState(), savedPaymentMethods)) {
          errorMonitor.captureMessage(
            "Default payment method doesn't match the one selected on the client. Previously, the latest updated method was chosen. Check server logic.",
          );
        }

        return methods;
      }

      const demoMethods =
        demoView === DemoView.EmptyState
          ? []
          : (await import('@utils/demoData')).getDemoPaymentMethods();

      fedops.interactionEnded(Interaction.LoadPaymentMethods);

      return demoMethods;
    } catch (error) {
      dispatch(
        appActions.setError({
          resolvedError: errorHandler.getResolvedError(error),
        }),
      );

      if (error instanceof Error) {
        errorMonitor.captureException(error);
      }

      throw rejectWithValue({ error });
    }
  },
);
