import type { IHttpClient, Experiments } from '@wix/yoshi-flow-editor';
import merge from 'lodash/merge';
import type { TypedErrorCodesMap, ErrorHandlerPublicAPI } from '@appTypes';

export type BaseHttpServiceConfig = {
  httpClient: IHttpClient;
  errorHandler: ErrorHandlerPublicAPI;
  experiments: Experiments;
};

export class BaseHttpService {
  private readonly errorHandler: ErrorHandlerPublicAPI;
  protected readonly httpClient: IHttpClient;
  protected readonly experiments: Experiments;
  /**
   * A property that allows overriding errors at the service level.
   * This object is then merged into the error overrides for each specific request.
   */
  protected errorCodesMap: TypedErrorCodesMap<unknown> = {};

  constructor(config: BaseHttpServiceConfig) {
    ({
      httpClient: this.httpClient,
      errorHandler: this.errorHandler,
      experiments: this.experiments,
    } = config);
  }

  protected withErrorHandler<T>(
    requestFn: () => Promise<T>,
    errorCodesMapOverride?: TypedErrorCodesMap<T>,
  ): Promise<T> {
    const errorCodesMap = merge({}, this.errorCodesMap, errorCodesMapOverride);

    return this.errorHandler.withErrorHandler(requestFn, {
      errorCodesMap,
    });
  }
}
