import type { TFunction } from '@wix/yoshi-flow-editor';
import type { PayPalPaymentMethod } from '@appTypes';
import { DEFAULT_PM_TITLE } from '@constants';

export const getPayPalTitle = (
  paymentMethod: PayPalPaymentMethod,
  t: TFunction,
  displayName: string | null | undefined,
): string => {
  const { email } = paymentMethod.paymentInfo;

  return t('app.payment-method.paypal.title', {
    title:
      displayName === DEFAULT_PM_TITLE
        ? t('app.payment-method.paypal.default-title')
        : displayName,
    email,
  });
};

export const getPayPalHolder = (
  paymentMethod: PayPalPaymentMethod,
  t: TFunction,
): string | undefined => {
  const { accountHolder } = paymentMethod.paymentInfo;

  if (accountHolder) {
    return t('app.payment-method.account-holder.label', { accountHolder });
  }
};
