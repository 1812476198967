import {
  Format,
  type SitePaymentMethodType,
} from '@wix/ambassador-payments-site-payment-method-types-v1-site-payment-method-type/types';
import type { Experiments } from '@wix/yoshi-flow-editor';
import type {
  PaymentMethodMeta,
  SavedPaymentMethod,
  SavedPaymentMethodWithMeta,
} from '@appTypes';
import { DEFAULT_PM_TITLE, PM_LOGO_TAG } from '@constants';
import ccNoBorderIconURI from '@assets/icons/creditCardNoBorder.svg?url';

export const concatWithMetaData = (
  savedPaymentMethods: SavedPaymentMethod[],
  experiments: Experiments,
): SavedPaymentMethodWithMeta[] => {
  const methodsWithMeta: SavedPaymentMethodWithMeta[] = [];

  savedPaymentMethods.forEach((savedPaymentMethod) => {
    const { paymentMethod } = savedPaymentMethod;

    if (paymentMethod) {
      const { cardInfo, accountInfo, ...paymentMethodDetails } = paymentMethod;

      const paymentMethodMeta = {
        displayName: '',
        image: {
          url: null,
        },
      };

      methodsWithMeta.push({
        ...savedPaymentMethod,
        ...paymentMethodMeta,
        paymentMethod: {
          ...paymentMethodDetails,
          // @ts-expect-error TODO fix after discussion
          //  https://wix.slack.com/archives/CFRR5LLAE/p1725614296101079?thread_ts=1722605830.020839&cid=CFRR5LLAE
          paymentInfo: cardInfo || accountInfo,
        },
      });
    }
  });

  return methodsWithMeta;
};

export function getPaymentMethodUIMetaData(
  paymentMethods?: SitePaymentMethodType[],
): PaymentMethodMeta[] | undefined {
  return paymentMethods?.map(({ id, displayName, buyerFlow }) => {
    const svgIcon =
      buyerFlow?.icons?.find(
        (icon) => icon.format === Format.SVG && icon.tag === PM_LOGO_TAG,
      ) || buyerFlow?.icons?.find((icon) => icon.format === 'SVG');

    const svgUrl = svgIcon?.image?.url ?? '';

    return {
      id: id ?? '',
      displayName: displayName ?? '',
      image: {
        url: svgUrl,
      },
    };
  });
}

const getMetaDataForPaymentMethod = (
  paymentMethod: SavedPaymentMethodWithMeta['paymentMethod'],
  metaDataArray: PaymentMethodMeta[],
): PaymentMethodMeta | undefined => {
  let metaData = metaDataArray.find(
    ({ id }) => id === paymentMethod?.paymentMethodBrandId,
  );

  if (!metaData?.image?.url) {
    metaData = metaDataArray.find(
      ({ id }) => id === paymentMethod?.paymentMethodTypeId,
    );
  }
  return metaData;
};

export const concatWithServiceMetaTypes = (
  savedPaymentMethods: SavedPaymentMethodWithMeta[],
  sitePaymentMethodTypes?: SitePaymentMethodType[],
): SavedPaymentMethodWithMeta[] => {
  if (!sitePaymentMethodTypes || !sitePaymentMethodTypes.length) {
    return savedPaymentMethods;
  }

  const metaDataArray = getPaymentMethodUIMetaData(sitePaymentMethodTypes);
  if (!metaDataArray || !metaDataArray.length) {
    return savedPaymentMethods;
  }

  return savedPaymentMethods
    .filter(({ paymentMethod }) => paymentMethod)
    .map((savedMethod) => {
      const { paymentMethod } = savedMethod;
      if (!paymentMethod) {
        return savedMethod;
      }

      const metaData = getMetaDataForPaymentMethod(
        paymentMethod,
        metaDataArray,
      );

      return {
        ...savedMethod,
        displayName: metaData?.displayName || savedMethod.displayName,
        image: {
          url: metaData?.image?.url || ccNoBorderIconURI,
        },
      };
    });
};

export const concatWithDefaultMetaTypes = (
  savedPaymentMethods?: SavedPaymentMethodWithMeta[],
): SavedPaymentMethodWithMeta[] | undefined => {
  if (!savedPaymentMethods) {
    return;
  }
  return savedPaymentMethods.map((savedPaymentMethod) => {
    return {
      ...savedPaymentMethod,
      displayName: DEFAULT_PM_TITLE,
      image: {
        url: ccNoBorderIconURI,
      },
    };
  });
};
